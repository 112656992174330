import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'http://stock.storefy.ma',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  headers: {
    // 'X-Requested-With': 'XMLHttpRequest', 
  // 'Access-Control-Allow-Origin' : 'http://stock.storefy.ma',
  // 'Access-Control-Allow-Headers': 'Content-Type, Authorization,X-ACCESS_TOKEN, Authorization,   Content-Range, Content-Disposition, Content-Description',
  // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
  // 'Access-Control-Allow-Credentials':true

},
  
  // window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
})

Vue.prototype.$http = axiosIns

export default axiosIns
