
export default{
    // API_BASE_URL: 'http://localhost:8000',
    // path:'http://stock.storefy.ma/back/storage/app/public/',
    // API_BASE_URL:'http://stock.storefy.ma/back/public'

    API_BASE_URL: 'http://stock.sc3bodm0204.universe.wf/back/public',
    path:'http://stock.sc3bodm0204.universe.wf/back/storage/app/public/',
}


// export const API_BASE_URL = '${config.API_BASE_URL}/';

