export default [
    {
      path: '/users',
      name: 'users-list',
      component: () => import('@/views/admin/user/users-list/UsersList.vue'),
    meta: {
      pageTitle: 'Utilisateurs',
      breadcrumb: [
        {
          text: 'Liste des utilisateurs',
          active:true
        },
       
      ],
    },
    },
    {
      path: '/DetailsUser/:id',
      name: 'details-user',
      component: () => import('@/views/admin/user/users-list/DetailsUser.vue'),
      meta: {
        pageTitle: 'Utilisateurs',
        breadcrumb: [
          {
            text: 'Historique & Permissions',
            to:'/users'
          },
         
        ],
      },
    },
    {
      path: '/BonAvoir',
      name: 'BonAvoir-list',
      component: () => import('@/views/admin/BonAvoir/BonAvoir-liste.vue'),
      meta: {
        pageTitle: 'Bons Avoir',
        breadcrumb: [
          {
            text: 'Bons Avoir',
            to:'/BonAvoir'
          },
          {
            text: 'Liste Colis',
            active: true,
          },
        ],
      },
    },
    {
      path: '/AddBonAvoir',
      name: 'BonAvoir-new',
      component: () => import('@/views/admin/BonAvoir/newBonavoir.vue'),
      meta: {
        pageTitle: 'Bon Avoir',
        breadcrumb: [
          {
            text: 'Bons Avoir',
            to:'/BonAvoir'
          },
          {
            text: 'Ajouter un bon d\'avoir',
            active: true,
          },
        ],
      },
    },
    // 
    {
      path: '/EditBonavoir/:id',
      name: 'BonAvoir-edit',
      component: () => import('@/views/admin/BonAvoir/EditBonavoir.vue'),
      meta: {
        pageTitle: 'Bon Avoir',
        breadcrumb: [
          {
            text: 'Bons Avoir',
            to:'/BonAvoir'
          },
          {
            text: 'Modifier un bon d\'avoir',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ShowBonavoir/:id',
      name: 'BonAvoir-show',
      component: () => import('@/views/admin/BonAvoir/PreviewBonAvoir.vue'),
      meta: {
        pageTitle: 'Bon Avoir',
        breadcrumb: [
          {
            text: 'Bons Avoir',
            to:'/BonAvoir'
          },
          {
            text: 'Voir un bon d\'avoir',
            active: true,
          },
        ],
      },
    },
    // EditBonavoir
     {
        path: '/fournisseurs',
        name: 'fournisseurs-list',
        component: () => import('@/views/admin/Fournisseurs/Fournisseurs-list.vue'),
        meta: {
          pageTitle: 'Fournisseurs',
          breadcrumb: [
            {
              text: 'Fournisseurs',
              to:'/fournisseurs'
            },
            {
              text: 'Liste des Fournisseurs',
              active: true,
            },
          ],
        },
      },
      // AddNewFournis
      {
        path: '/AddNewFournis',
        name: 'add-fournis',
        component: () => import('@/views/admin/Fournisseurs/AddNewFournis.vue'),
        meta: {
          pageTitle: 'Fournisseurs',
          breadcrumb: [
            {
              text: 'Fournisseurs',
              to:'/fournisseurs'
            },
            {
              text: 'Nouveau Fournisseur',
              active: true,
            },
          ],
        },
      },
     
      {
        path: '/produits',
        name: 'produits-list',
        component: () => import('@/views/admin/Produits/Product-list.vue'),
        meta: {
          pageTitle: 'Products',
          breadcrumb: [
            {
              text: 'Liste Product',
              active: true,
            },
            // {
            //   text: 'Leaflet',
            //   active: true,
            // },
          ],
        },
      },
      {
        path: '/Categories',
        name: 'categories-list',
        component: () => import('@/views/admin/Categories/Categories.vue'),
        meta: {
          pageTitle: 'Catégories',
          breadcrumb: [
            {
              text: 'Liste des catégories',
              active: true,
            },
            // {
            //   text: 'Leaflet',
            //   active: true,
            // },
          ],
        },
      },
      {
        path: '/Stock/:id',
        name: 'voir-stock-product',
        component: () => import('@/views/admin/Produits/StockProduct.vue'),
        meta: {
          pageTitle: 'Products',
          breadcrumb: [
            {
              text: 'Produits',
              active: false,
              to: { name: 'produits-list' }
            },
            {
              text: 'Stock',
              active: true,
            },
          ],
        },
      },
     
      {
        path: '/clients',
        name: 'clients-list',
        component: () => import('@/views/admin/Clients/Clients-list.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Liste Clients',
              active: true,
              to: { name: 'clients-list' }
            },
            // {
            //   text: 'Leaflet',
            //   active: true,
            // },
          ],
        },
      },
      {
        // FactureFournisseur-list.vue
        path: '/DevisClient',
        name: 'devis-client-list',
        component: () => import('@/views/admin/Clients/Devis.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Clients',
              active: false,
              to: { name: 'clients-list' }
            },
            {
              text: 'Devis Clients',
              active: true,
            },
          ],
        },
      },
      // AddDevis
      {
        path: '/AddDevisClient',
        name: 'devis-add',
        component: () => import('@/views/admin/Clients/AddDevis.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Devis Clients',
              to: { name: 'devis-client-list' }
            },
            {
              text: 'Ajouter un devis',
              active: true,
            },
          ],
        },
      },
      {
        path: '/Devis/UpdateDevis/:id',
        name: 'devis-update',
        component: () => import('@/views/admin/Clients/UpdateDevis.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Devis Clients',
              to: { name: 'devis-client-list' }
            },
            {
              text: 'Modifier un devis',
              active: true,
            },
          ],
        },
      },
      // 
      {
        path: '/BL/EditBL/:id',
        name: 'bl-update',
        component: () => import('@/views/admin/Clients/EditBL.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Bons Livraison',
              to: { name: 'clients-list' }
            },
            
            {
              text: 'Modifier un bon livraison',
              active: true,
            },
          ],
        },
      },
      {
        path: '/Devis/PreviewDevis/:id',
        name: 'devis-preview',
        component: () => import('@/views/admin/Clients/PreviewDevis.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Devis Clients',
              active: false,
              to: { name: 'devis-client-list' }
            },
            {
              text: 'Imprimer un devis',
              active: true,
            },
          ],
        },
      },
      // BonLivraison
      {
        path: '/BonLivraison',
        name: 'bl-client-list',
        component: () => import('@/views/admin/Clients/BonLivraison.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Clients',
              to: { name: 'clients-list' }
            },
            {
              text: 'Bon de livraison',
              active: true,
            },
          ],
        },
      },
      {
        path: '/AddBLClient',
        name: 'BL-add',
        component: () => import('@/views/admin/Clients/AddBonLivraison.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Bon Livraison',
              to: { name: 'bl-client-list' }
            },
            {
              text: 'Ajouter un Bon livraison',
              active: true,
            },
          ],
        },
      },
            // FactureClient
      {
        path: '/FactureClient',
        name: 'fact-client-list',
        component: () => import('@/views/admin/Clients/Factures/FactureClient.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Clients',
              to: { name: 'clients-list' }
            },
            {
              text: 'Factures Clients',
              active: true,
            },
          ],
        },
      },
      {
        path: '/Clients/AddFact',
        name: 'fact-client-add',
        component: () => import('@/views/admin/Clients/Factures/AddFact.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Factures Clients',
              to: { name: 'fact-client-list' }
            },
            {
              text: 'Ajouter une facture',
              active: true,
            },
          ],
        },
      },
      // AddFact
      {
        path: '/Clients/PreviewFact/:id/:clientId',
        name: 'fact-client-preview',
        component: () => import('@/views/admin/Clients/Factures/PreviewFact.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Factures Clients',
              to: { name: 'fact-client-list' }
            },
            {
              text: 'Voir une facture',
              active: true,
            },
          ],
        },
      },
      // UpdateFactureClient.vue
      {
        path: '/UpdateFact/:id',
        name: 'fact-client-update',
        component: () => import('@/views/admin/Clients/Factures/UpdateFactureClient.vue'),
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Factures Clients',
              active: false,
              to: { name: 'fact-client-list' }
            },
            {
              text: 'Modifier Facture',
              active: true,
            },
          ],
        },
      },
      {
        // FactureFournisseur-list.vue
        path: '/FactureFournisseur',
        name: 'facture-fournis-list',
        component: () => import('@/views/admin/FactureFournisseur/FactureFournisseur-list.vue'),
        meta: {
          pageTitle: 'Fournisseurs',
          breadcrumb: [
            {
              text: 'Factures Fournisseurs',
              active: true,
            },
          ],
        },
      },
      {
        // FactureFournisseur-list.vue
        path: '/FactureFournisseurList',
        name: 'facture-list',
        component: () => import('@/views/admin/FactureFournisseur/list.vue'),
        meta: {
          pageTitle: 'Fournisseurs',
          breadcrumb: [
            {
              text: 'Factures Fournisseurs',
              active: true,
            },
          ],
        },
      },
      {
        path: '/AddFactureFournisseur',
        name: 'facture-fournis-add',
        component: () => import('@/views/admin/FactureFournisseur/AddFacture.vue'),
        meta: {
          pageTitle: 'Fournisseurs',
          breadcrumb: [
            {
              text: 'Factures Fournisseurs',
              to:'/AddFactureFournisseur'
            },
            {
              text: 'Ajouter Factures Fournisseurs',
              active: true,
            },
          ],
        },
      },
      {
        path: '/fournisseurs/Facture/show/:id',
        name: 'fact-fourni-preview',
        component: () => import('@/views/admin/FactureFournisseur/FacturePreview.vue'),
        meta: {
          pageTitle: 'Fournisseurs',
          breadcrumb: [
            {
              text: 'Factures Fournisseurs',
              to:'/AddFactureFournisseur'
            },
            {
              text: 'Voir Factures Fournisseurs',
              active: true,
            },
          ],
        },
      },
      {
        path: '/fournisseurs/Facture/Edit/:id',
        name: 'fact-fourni-edit',
        component: () => import('@/views/admin/FactureFournisseur/EditFacture.vue'),
        meta: {
          pageTitle: 'Fournisseurs',
          breadcrumb: [
            {
              text: 'Factures Fournisseurs',
              to:'/AddFactureFournisseur'
            },
            {
              text: 'Modifier Factures Fournisseurs',
              active: true,
            },
          ],
        },
      },

      // Settings settings.vue
      {
        path: '/settings',
        name: 'settings-page',
        component: () => import('@/views/admin/Settings/settings.vue'),
        meta: {
          pageTitle: 'Paramètrage',
          breadcrumb: [
            {
              text: 'Paramètres',
              active: true,
              to: { name: 'settings-page' }
            },
            
          ],
        },
      },


      // bons d'avoir
      {
        // FactureFournisseur-list.vue
        path: '/bonCommandes',
        name: 'commandes_list',
        component: () => import('@/views/admin/Fournisseurs/Bon Commande/CommandeList.vue'),
        meta: {
          pageTitle: 'Bons de commande',
          breadcrumb: [
            {
              text: 'Liste des bons de commande',
              active: true,
            },
          ],
        },
      },
      {
        path: '/AddBonCmd',
        name: 'AddBonCmd',
        component: () => import('@/views/admin/Fournisseurs/Bon Commande/newBonCmd.vue'),
        meta: {
          pageTitle: 'Bons de commande',
          breadcrumb: [
            {
              text: 'Bons de commande',
              to:'/bonCommandes'
            },
            {
              text: 'Ajouter un bon de commande',
              active: true,
            },
          ],
        },
      },
      // 
      {
        path: '/EditBonCmd/:id',
        name: 'BonCmdEdit',
        component: () => import('@/views/admin/Fournisseurs/Bon Commande/editBonCmd.vue'),
        meta: {
          pageTitle: 'Bons de commande',
          breadcrumb: [
            {
              text: 'Bons de commande',
              to:'/bonCommandes'
            },
            {
              text: 'Modifier un bon de commande',
              active: true,
            },
          ],
        },
      },
      {
        path: '/ShowBonCmd/:id',
        name: 'BonCmdShow',
        component: () => import('@/views/admin/Fournisseurs/Bon Commande/showBonCmd.vue'),
        meta: {
          pageTitle: 'Bons de commande',
          breadcrumb: [
            {
              text: 'Bons de commande',
              to:'/bonCommandes'
            },
            {
              text: 'Voir un bon de commande',
              active: true,
            },
          ],
        },
      },
  ]